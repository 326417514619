#text {
  color: black;
  font-family: "Fira Sans", sans-serif;
  font-weight: 500;
}

#boldText {
  color: black;
  font-family: "Fira Sans", sans-serif;
  font-weight: 700;
}

#resultENV {
  display: flex;
  height: fit-content;
  width: 100vw;
  justify-content: center;
  align-self: center;
}

#resultENVCard {
  height: fit-content;
  min-width: 60vw;
  text-align: center;
  background-color: white;
  border-radius: 1.5rem;
}

#firstColumn {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 2%;
}

#carbonCard {
  width: 15vw;
  height: 20vh;
  background-color: white;
  border-radius: 1.5rem;
}

#breakDownDiv {
  min-width: 20vw;
  max-width: fit-content;
}

#breakDownCard {
  background-color: white;
  border-radius: 1.5rem;
}

#breakDownTable {
  vertical-align: middle;
  margin-top: 2%;
}

#colorBox {
  color: rgb(66, 107, 94);
}

#submitButtonDivMiddle {
  align-self: center;
}

#secondColumn {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 2%;
}