#text {
  color: black;
  font-family: "Fira Sans", sans-serif;
  font-weight: 500;
}

#boldText {
  color: black;
  font-family: "Fira Sans", sans-serif;
  font-weight: 700;
}

#createSDG {
  display: flex;
  min-height: 100%;
  width: 100vw;
  align-self: center;
  justify-content: center;
}

#selectingSDG {
  display: flex;
  height: fit-content;
  width: 50vw;
  background-color: white;
  border-radius: 1.5rem;
}

#selectingImgGrid {
  width: 30vw;
  margin-top: 2%;
  margin-left: 10%;
  justify-content: center;
}

#arrayImg {
  margin-bottom: 3%;
  margin-right: 3%;
}

#saveButtonDiv {
  margin-top: 1%;
  margin-right: 5%;
  text-align: end;
}

#saveButton {
  color: white;
  background-color: rgb(66, 107, 94);
  font-family: "Fira Sans", sans-serif;
  font-weight: 500;
}

#saveButton:hover {
  opacity: 60%;
}