#text {
  color: black;
  font-family: "Fira Sans", sans-serif;
  font-weight: 500;
}

#boldText {
  color: black;
  font-family: "Fira Sans", sans-serif;
  font-weight: 700;
}

#reporting {
  display: flex;
  height: fit-content;
  width: 100vw;
  justify-content: center;
  align-self: center;
}

.reportTitle {
  text-align: center;
}

#newReportButton {
  margin-top: 5%;
}

#newReportButton:hover {
  cursor: pointer;
  opacity: 60%;
}

#existingReports {
  margin-top: 5%;
  height: fit-content;
  min-width: 65vw;
  text-align: center;
  background-color: white;
  border-radius: 1.5rem;
}

#reportTable {
  vertical-align: middle;
  text-align: center;
  margin-top: 2%;
}
