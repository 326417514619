.DashboardContainer {
  display: flex;
  height: fit-content;
  width: 100vw;
  justify-content: center;
  align-self: flex-start;
  overflow: hidden;
}

.title {
  color: black;
  font-family: "Fira Sans", sans-serif;
  font-weight: 700;
}

.text {
  color: black;
  font-family: "Fira Sans", sans-serif;
  font-weight: 500;
}

.ESGMetrics {
  display: flex;
  height: 18%;
  width: 40%;
  background-color: white;
  border-radius: 1.5rem;
}

.ESGMetrics .imgGrid {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 2%;
}

.ESGMetrics .imgGridText{
  display: flex;
  justify-content: space-evenly;
  margin-top: 0.9rem;
}

.climateImpact {
  display: flex;
  height: 50%;
  width: 40%;
  background-color: white;
  border-radius: 1.5rem;
}

.climateImpact .dateInput {
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 57%;
  margin-top: 10%;
}

.climateImpact .topInfo {
  flex: 1;
  text-align: center;
  margin-top: 5%;
}

.climateImpact .graph {
  margin-top: 10%;
}

.SDGSummary {
  display: flex;
  height: 32%;
  width: 30%;
  background-color: white;
  border-radius: 1.5rem;
}

.SDGSummary .subHeading {
  opacity: 70%;
  margin-top: 5%;
  color: black;
  font-family: "Fira Sans", sans-serif;
  font-weight: 700;
}

.SDGSummary .imgGrid {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.SDGSummary .secImg {
  opacity: 50%;
}

.Reports {
  display: flex;
  height: 16%;
  width: 30%;
  background-color: white;
  border-radius: 1.5rem;
}

.Reports .imgGrid {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}