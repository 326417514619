#text {
  color: black;
  font-family: "Fira Sans", sans-serif;
  font-weight: 500;
}

#boldText {
  color: black;
  font-family: "Fira Sans", sans-serif;
  font-weight: 700;
}

#searchBar {
  display: flex;
  position: fixed;
  top: 0;
  height: fit-content;
  width: 100%;
  justify-content: end;
  margin-top: 2vh;
}

#searchColumn {
  display: flex;
  margin-right: 5vw;
  align-items: center;
}

#searchIcon {
  margin-top: 2%;
  margin-right: 2%;
}

#search {
  width: 15vw;
  height: 4.3vh;
  border-radius: 1rem;
}

#profilePic {
  margin-left: 5%;
}