#text {
  color: black;
  font-family: "Fira Sans", sans-serif;
  font-weight: 500;
}

#boldText {
  color: black;
  font-family: "Fira Sans", sans-serif;
  font-weight: 700;
}

#createReport {
  display: flex;
  height: fit-content;
  width: 100vw;
  align-self: center;
  text-align: center;
  justify-content: center;
}

#columnDiv {
  width: 60vw;
}

#buttonGrid {
  display: flex;
  justify-content: space-evenly;
  margin-top: 7%;
}

#reportButton {
  height: 8rem;
  width: 8rem;
  border: 0.2rem solid black;
  border-radius: 0.5rem;
  background-color: white;
}

#reportButton:hover {
  cursor: pointer;
  opacity: 60%;
}
