#text {
  color: black;
  font-family: "Fira Sans", sans-serif;
  font-weight: 500;
}

#boldText {
  color: black;
  font-family: "Fira Sans", sans-serif;
  font-weight: 700;
}

#largeLogoDiv {
  display: flex;
  justify-content: center;
  margin-top: 30%;
  margin-bottom: 20%;
}

#forgotpassword {
  display: flex;
  height: fit-content;
  width: 100vw;
  justify-content: center;
  align-self: flex-start;
}

#forgotpasswordCard {
  height: fit-content;
  min-width: 30vw;
  background-color: white;
  border-radius: 1rem;
}

#groups {
  margin-top: 3%;
}

#forgotpasswordButton {
  margin-top: 5%;
  width: 100%;
  color: white;
  background-color: rgb(66, 107, 94);
  font-family: "Fira Sans", sans-serif;
  font-weight: 500;
}

#linkDiv {
  text-align: center;
  margin-top: 3%;
  font-family: "Fira Sans", sans-serif;
  font-weight: 500;
}