#text {
  color: black;
  font-family: "Fira Sans", sans-serif;
  font-weight: 500;
}

#boldText {
  color: black;
  font-family: "Fira Sans", sans-serif;
  font-weight: 700;
}

#env {
  display: flex;
  height: fit-content;
  width: 100vw;
  align-self: center;
  justify-content: center;
}

.ENVTitle {
  text-align: center;
}

#newENVButton {
  margin-top: 5%;
}

#newENVButton:hover {
  cursor: pointer;
  opacity: 60%;
}

#envSummary {
  display: flex;
  height: fit-content;
  min-width: 65vw;
  margin-top: 5%;
  text-align: center;
  background-color: white;
  border-radius: 1.5rem;
}

#dateRangeDiv {
  display: flex;
  flex-direction: row;
  margin-top: 2%;
  margin-right: 5%;
  justify-content: end;
}

#inputLabel {
  color: black;
  font-family: "Fira Sans", sans-serif;
  font-weight: 500;
}

#bottomDiv {
  display: flex;
  flex-direction: row;
  margin-top: 2%;
  justify-content: end;
}

#graphDiv {
  margin-right: 3%;
  width: 40vw;
}

#climateInfoDiv {
  margin-right: 25%;
}

#infoDiv {
  margin-top: 15%;
}